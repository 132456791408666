<template>
  <div class="main" style="min-height: 80vh;background-color: #eaedf1;">
    <!-- 限定宽度1 -->
    <div class="breadmenu">
      <a-breadcrumb>
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item><a href="/findproduct/index">发现商品</a></a-breadcrumb-item>
        <a-breadcrumb-item>全部商品</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="ssss">
      <div class="top">
        <a-input-search v-model="searchData.subject" placeholder="搜素商品" @search="onSearch" />
      </div>
      <div class="product">
        <div class="product-menu">
          <div class="menu-top" @click="goBack()">
            <i class="el-icon-arrow-left" />返回菜单
          </div>
          <div class="menu-title">
            全部产品
          </div>

          <div style="color: #7a8499;font-size: 12px;">
            共 {{ page.total }} 个商品
          </div>
          <!-- animated slideInRight -->
          <div v-if="choose" class="menu-type">
            <div class="menu-type-title">
              全部产品
            </div>
            <!-- 菜单 -->
            <div v-for="(item, index) in productTypeList" :key="index" class="menu-type-subtitle" @click="changeMenu(item.cateId)">
              {{ item.cateName }} <i class="el-icon-arrow-right" />
            </div>
          </div>

          <!-- animated slideInLeft -->
          <div v-if="choosea" class="menu-type">
            <div class="menu-type-title" @click="changeMenuSub">
              <i class="el-icon-arrow-left" /> 电子产品
            </div>
            <div>
              <el-menu
                default-active="2"
              >
                <el-submenu index="1-4">
                  <template slot="title">
                    分类1
                  </template>
                  <el-menu-item index="1-4-1">
                    选项1
                  </el-menu-item>
                </el-submenu>
              </el-menu>
            </div>
          </div>

          <!-- 条件 -->
          <div class="menu-type">
            <div class="menu-type-title">
              筛选
            </div>

            <div class="sai">
              <div class="sai-layout">
                <div>
                  价格范围
                </div>
                <div class="poiter" @click="clearSearch(0)">
                  清空
                </div>
              </div>
              <a-radio-group v-model="search.price" @change="onChange">
                <a-radio v-for="(item,index) in searchPriceList" :key="index" :value="item.value" class="s-block">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </div>

            <div v-if="false" class="sai">
              <div class="sai-layout">
                <div>
                  上架日期
                </div>
                <div class="poiter" @click="clearSearch(1)">
                  清空
                </div>
              </div>
              <a-radio-group v-model="search.date" @change="onChange">
                <a-radio v-for="(item,index) in searchDateList" :key="index" :value="item.value" class="s-block">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </div>
            <div v-if="false" class="sai">
              <div class="sai-layout">
                <div>
                  评价数
                </div>
                <div class="poiter" @click="clearSearch(2)">
                  清空
                </div>
              </div>
              <a-radio-group v-model="search.num" @change="onChange">
                <a-radio v-for="(item,index) in searchNumList" :key="index" :value="item.value" class="s-block">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </div>

            <div v-if="false" class="sai">
              <div class="sai-layout">
                <div>
                  商品特辑
                </div>
                <div class="poiter" @click="clearSearch(3)">
                  清空
                </div>
              </div>
              <a-radio-group v-model="search.hot" @change="onChange">
                <a-radio v-for="(item,index) in searchHotList" :key="index" :value="item.value" class="s-block">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>

          <!-- end -->
        </div>
        <div class="product-content">
          <div class="content-top">
            <el-select v-model="selectType" size="small" placeholder="请选择">
              <el-option
                v-for="item in selectTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>

            <i class="el-icon-s-grid change-tag" @click="cardType = !cardType" />
          </div>
          <!-- 标签 -->
          <div class="product-tags">
            <a-tag v-for="(item, index) in tagList" :key="index" color="#108ee9" closable @close="closeTag(index)">
              {{ item.label }}
            </a-tag>
          </div>
          <div v-loading="loading" class="productlist">
            <template v-if="cardType">
              <div v-for="(item, index) in productList" :key="index" class="card-s" @click="todetails(item)">
                <card :item="item" />
              </div>
            </template>
            <template v-else>
              <div v-for="(item, index) in productList" :key="index" class="card-s" @click="todetails(item)">
                <cardlittle :item="item" />
              </div>
            </template>
          </div>
          <div :class="lastNone?'lastNone':'lastBlock'">
            <div :class="firstNone?'firstNone':'firstBlock'">
              <el-pagination
                class="fenye"
                background
                :current-page="page.current"
                :page-sizes="[10, 20]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// getBrandProductList
import { getAlibabaProductList, getBrandProductList, getBrandCategory } from '@/api/register'
import card from './selectionlistcomponent/productCard.vue'
import cardlittle from './selectionlistcomponent/productLittleCard.vue'
import { searchPriceList, searchDateList, searchNumList, searchHotList } from './searchproduct'
export default {
  name: 'Searchproduct',
  components: {
    card,
    cardlittle
  },
  data() {
    return {
      firstNone: '',
      lastNone: '',

      tagList: [],

      choose: true,
      choosea: false,

      cardType: true,
      search: {
        price: -1,
        date: 0,
        num: 0,
        hot: 0
      },
      searchPriceList: searchPriceList,
      searchDateList: searchDateList,
      searchNumList: searchNumList,
      searchHotList: searchHotList,
      selectType: '1',
      selectTypeList: [
        { label: '阿里巴巴', value: '1' },
        { label: '品牌', value: '2' }
      ],
      productList: [],

      loading: false,
      page: { size: 20, current: 1, total: 0 },
      searchData: { // 保存查询数据
        subject: null,
        categoryId: null
      },
      searchObj: {}, // 用于查询的对象
      productTypeList: []
    }
  },
  watch: {
    selectType(newValue) {
      // 重新查询
      this.onSearch()

      if (newValue === '2') {
        this.getBrandCategory()
      }
      if (newValue === '1') {
        this.productTypeList = []
      }
    }
  },
  mounted() {
    // 标签查询
    // const search = this.$route.query.search
    // this.tagList.push(search)

    // 1.searchSubject 是页面传参-搜索关键字
    // 2.将其赋值给查询对象
    const searchSubject = this.$route.query.subject
    if (searchSubject !== '' || searchSubject !== null) {
      this.searchObj.subject = searchSubject
      this.searchData.subject = searchSubject
    }
    this.getList()
  },
  methods: {
    /** 返回上一页 */
    goBack() {
      this.$router.go(-1)
    },

    // 标签事件
    closeTag(index) {
      this.tagList.splice(index, 1)
    },

    // 分类
    getBrandCategory() {
      getBrandCategory().then(res => {
        if (res.code === 0) {
          this.productTypeList = res.data.slice(0, 10)
        }
      })
    },

    /**
     * @title 搜索列表的方法
     * 1. 搜索时重置页码
     * 2. 去除searchData数据空白属性
     * 3. 将搜索数据对象searchData 赋值给 搜索对象searchObj
     */
    onSearch() {
      this.page.current = 1

      for (const key in this.searchData) {
        if (Object.hasOwnProperty.call(this.searchData, key)) {
          if (this.searchData[key] === '') {
            this.searchData[key] = null
          }
        }
      }

      this.searchObj = this.searchData

      this.getList()
    },
    changeMenu(cateId) {
      this.choose = false
      this.choosea = true

      // 刷新一下列表
      this.searchData.categoryId = cateId
      this.onSearch()
    },

    /** 菜单被点击 */
    changeMenuSub() {
      this.choosea = false
      this.$nextTick(() => {
        this.choose = true
      })
    },

    /** 条件查询 */
    onChange() {
      if (this.search.price !== -1) {
        const priceIndex = this.search.price
        this.searchData.priceDown = this.searchPriceList[priceIndex].startprice
        this.searchData.priceUp = this.searchPriceList[priceIndex].endprice
      }
      // if (index === 1) {
      //   this.search.date = 0
      // }
      // if (index === 2) {
      //   this.search.num = 0
      // }
      // if (index === 3) {
      //   this.search.hot = 0
      // }

      // 重新查询
      this.onSearch()
    },

    /** 清空条件查询 */
    clearSearch(index) {
      if (index === 0) {
        this.search.price = -1
        this.searchData.priceDown = null
        this.searchData.priceUp = null
      }
      if (index === 1) {
        this.search.date = -1
      }
      if (index === 2) {
        this.search.num = -1
      }
      if (index === 3) {
        this.search.hot = -1
      }

      // 重新查询
      this.onSearch()
    },
    todetails(item) {
      if (!item.productId) {
        return
      }
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: this.selectType, product: item.wholesalePrice }})
    },

    /**
     * @title 获取产品列表数据：品牌、1688
     */
    getList() {
      this.loading = true
      if (this.selectType === '1') {
        const pageObj = {}
        pageObj.size = this.page.size
        pageObj.current = this.page.current

        getAlibabaProductList({ ...pageObj, ...this.searchObj })
          .then((result) => {
            if (result.code === 0) {
              this.loading = false
              this.productList = result.data.records
              this.page.total = result.data.total
              this.lastLi()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        const pageObj = {}
        pageObj.size = this.page.size
        pageObj.current = this.page.current

        getBrandProductList({ ...pageObj, ...this.searchObj })
          .then((result) => {
            if (result.code === 0) {
              this.loading = false
              this.productList = result.data.records
              this.page.total = result.data.total
              this.lastLi()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    handleSizeChange(size) {
      this.page.size = size
      this.page.current = 1
      this.getList()
      this.$el.parentElement.scrollTop = 0
    },
    handleCurrentChange(current) {
      this.page.current = current
      this.getList()
      this.$el.parentElement.scrollTop = 0
    },
    lastLi() {
      // 记得加上 this.$nextTick，不然获取不到document
      this.$nextTick(() => {
        this.lastNone = !!document.getElementsByClassName('btn-quicknext')[0]
        this.firstNone = !!document.getElementsByClassName('btn-quickprev')[0]
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .fenye {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  .main{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ssss{
    width: 100%;
    padding: 0 30px;
    max-width: 1400px;
  }
  .top{
    margin: 50px 10% 30px;
  }
  .product{
    display: flex;
    text-align: left;
    &-menu{
      width: 14.2%;
      min-width: 220px;
      max-width: 270px;
    }

    &-content{
      width: 80%;
      max-width: 1080px;
      padding-bottom: 20px;
      margin: 0 10px;
      transform: translateZ(0)
    }
  }
  .menu-top{
    margin: 20px 0 4px;
    color: #7a8499;
    font-size: 12px;
    cursor: pointer;
  }
  .menu-title{
    width: 300%;
    font-weight: 600;
    font-size: 30px;
    color: #000;
  }
  .menu-type{
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 6px;
  }
  .menu-type-title{
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(215, 219, 231);
    cursor: pointer;
  }
  .menu-type-subtitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #242833;
    font-weight: 500;
    cursor: pointer;
  }

  .content-top{
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .change-tag{
    margin-left: 10px;
    font-size: 25px;
  }

  // 内容
  .productlist{
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
  }

  // 卡片
  .card-s{
    text-align: center;
    margin-bottom: 16px;
    padding:0 5px
  }

  // 筛选
  .sai{
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d7dbe7;
  }
  .sai-layout{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .poiter{
    cursor: pointer;
  }

  .s-block{
    margin: 8px 0;
    display:block
  }

  // 面包屑
  .breadmenu{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    border-top: #eaedf1 solid 1px;
    width: 100%;
    text-align: left;
    padding: 8px;
    background: #fff;
  }
</style>
<style lang="scss" scoped>
  ::v-deep .el-submenu .el-menu-item{
    min-width:unset
  }
  ::v-deep .el-menu {
    border-right: unset;
  }

  // 分页的
  // 后
 .lastNone ::v-deep .el-pagination{
		.el-pager{
			li:nth-last-child(1){
				display: none;
			}
		}
	}

  .lastBlock ::v-deep	.el-pagination{
		.el-pager{
			li:nth-last-child(1){
				display: inlibe-block;
			}
		}
	}
  // 前
  .firstNone ::v-deep .el-pagination{
		.el-pager{
			li:first-child{
				display: none;
			}
		}
	}

  .firstBlock ::v-deep	.el-pagination{
		.el-pager{
			li:first-child{
				display: inlibe-block;
			}
		}
	}

</style>

// 用于查询的列表

/** 价格查询 */
export const searchPriceList = [
  {
    label: '$0 - $100',
    value: 0,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '$100 - $500',
    value: 1,
    startprice: '100',
    endprice: '500'
  },
  {
    label: '$500 - $1000',
    value: 2,
    startprice: '500',
    endprice: '1000'
  },
  {
    label: '$1000以上',
    value: 3,
    startprice: '1000',
    endprice: null
  }
]
/** 日期查询 */
export const searchDateList = [
  {
    label: '近7日',
    value: 0,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '近14日',
    value: 1,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '近30日',
    value: 2,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '近90日',
    value: 3,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '近180日',
    value: 4,
    startprice: '0',
    endprice: '100'
  },
  {
    label: '180日以上',
    value: 5,
    startprice: '0',
    endprice: '100'
  }
]
/** 评论数查询 */
export const searchNumList = [
  {
    label: '10个以上',
    value: 0,
    startprice: '',
    endprice: ''
  },
  {
    label: '50个以上',
    value: 1,
    startprice: '',
    endprice: ''
  },
  {
    label: '100个以上',
    value: 2,
    startprice: '',
    endprice: ''
  },
  {
    label: '500个以上',
    value: 3,
    startprice: '',
    endprice: ''
  }
]
/** 热点查询 */
export const searchHotList = [
  {
    label: '当前热卖',
    value: 0,
    startprice: '',
    endprice: ''
  },
  {
    label: '飙升新品',
    value: 1,
    startprice: '',
    endprice: ''
  }
]

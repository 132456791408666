<template>
  <el-card shadow="hover" class="spu-card">
    <!-- 图片 -->
    <div class="image-s">
      <img class="image-i" :src="item.image" alt="" srcset="">
    </div>
    <div class="sub-price">
      ¥ {{ item.wholesalePrice }}
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spu-card {
  cursor: pointer;
  width: 94px !important; // 200 大小
  display: flex;
  align-items: center;
}
.image-s {
  background-color: #fff;
  width: 94px;
  height: 94px;
  position: relative;
}
.image-i {
  object-fit: contain;
  object-position: center;
  height: 100%;
  z-index: 100;
}
.sub-price {
  margin-top: 8px;
  color: #474f5e;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin: 8px 12px;
}

::v-deep .el-card__body{
  padding: 0px !important;
}
</style>
